import { Icon } from 'display-components/icon-list/icon-list';
import MedicineCard from 'display-components/medicine-card/medicine-card';

import { MedicinesCardsMock } from 'util/birdi-select-conditions.mock';

import { BirdiSelectInfoBannerMedicationResults } from '../birdi-select-info-banner-medication-results/birdi-select-info-banner-medication-results';
import './birdi-select-browse-medications-results-by-icon.style.scss';

interface BirdiSelectBrowseMedicationsByIconProps {
    selectedIcon: Icon;
    onClick?: () => void;
}

export const BirdiSelectBrowseMedicationsByIcon = ({ selectedIcon }: BirdiSelectBrowseMedicationsByIconProps) => {
    const medicines = MedicinesCardsMock.filter((item, _) => item.groupId === selectedIcon.id).sort((a, b) =>
        a.name.localeCompare(b.name)
    );

    return (
        <div className="birdi-select-browse-medications-by-icon">
            <BirdiSelectInfoBannerMedicationResults icon={selectedIcon} />
            <div className="birdi-select-browse-medications-by-icon__medications_results">
                {medicines.map((item, index) => (
                    <MedicineCard
                        key={index}
                        name={item.name}
                        description={item.description}
                        dose={item.dose}
                        ndc={item.ndc}
                        gpi={item.gpi}
                    />
                ))}
            </div>
        </div>
    );
};
