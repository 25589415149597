import { noop } from 'lodash';
import React, { forwardRef, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Icon from 'ui-kit-v2/icon/icon';
import TextInputComponent from 'ui-kit-v2/text-input/text-input';
import { v4 as uuidv4 } from 'uuid';

import { DropdownProps } from './dropdown.props';
import './dropdown.styles.scss';

const DropdownComponent = forwardRef<HTMLInputElement, DropdownProps>(
    (
        {
            id,
            options,
            onSearch,
            onSelect,
            label,
            error,
            success,
            optionNotFoundText,
            contentLeft,
            contentRight,
            variant = 'default',
            disabled = false,
            readOnly = false,
            canSearch = false,
            defaultValue = null,
            ...props
        },
        ref
    ) => {
        const [open, setOpen] = useState(false);
        const [selectedValue, setSelectedValue] = useState<string | null>(defaultValue?.toString() || null);
        const [selectedLabel, setSelectedLabel] = useState<string | null>(null);
        const [searchTerm, setSearchTerm] = useState<string>('');
        const [focusedIndex, setFocusedIndex] = useState<number>(-1);
        const optionRefs = useRef<(HTMLLIElement | null)[]>([]);
        const dropdownId = useMemo(() => id || uuidv4(), [id]);
        const dropdownRef = useRef<HTMLDivElement>(null);

        const toggleDropdown = useCallback(() => {
            if (!readOnly && !disabled) {
                setOpen((prev) => !prev);
            }
        }, [readOnly, disabled]);

        const openDropdown = useCallback(() => {
            setOpen(true);
            setFocusedIndex(-1);
        }, []);

        const closeDropdown = useCallback(() => {
            setOpen(false);
            setSearchTerm('');
        }, []);

        const handleSelect = useCallback(
            (value: any, label: ReactNode) => {
                const labelText = extractTextFromReactNode(label);
                setSelectedValue(value);
                setSelectedLabel(labelText);
                onSelect?.(value);
                closeDropdown();
            },
            [onSelect, closeDropdown]
        );

        const highlightMatch = (label: ReactNode, searchTerm?: string): ReactNode => {
            if (!searchTerm) return label;

            const recursivelyHighlight = (node: ReactNode): ReactNode => {
                if (typeof node === 'string') {
                    const regex = new RegExp(`(${searchTerm})`, 'gi');
                    const parts = node.split(regex);
                    return parts.map((part, index) => (regex.test(part) ? <strong key={index}>{part}</strong> : part));
                }

                if (Array.isArray(node)) {
                    return node.map((child, index) => <span key={index}>{recursivelyHighlight(child)}</span>);
                }

                if (typeof node === 'object' && node !== null && 'props' in node) {
                    return React.cloneElement(node, { ...node.props }, recursivelyHighlight(node.props.children));
                }

                return node;
            };

            return recursivelyHighlight(label);
        };

        const handleInputChange = useCallback(
            (event: React.ChangeEvent<HTMLInputElement>) => {
                if (canSearch) {
                    const query = event.target.value;
                    setSearchTerm(query);
                    onSearch?.(query);
                }
            },
            [canSearch, onSearch]
        );

        const extractTextFromReactNode = (node: ReactNode): string => {
            if (typeof node === 'string') {
                return node;
            }

            if (Array.isArray(node)) {
                return node.map(extractTextFromReactNode).join(' ');
            }

            if (typeof node === 'object' && node !== null && 'props' in node) {
                return extractTextFromReactNode(node.props.children);
            }

            return '';
        };

        const filteredOptions = useMemo(() => {
            if (!canSearch || searchTerm.trim() === '') {
                return options;
            }

            return options.filter((option) => {
                const labelText = extractTextFromReactNode(option.label);
                return labelText.toLowerCase().includes(searchTerm.toLowerCase());
            });
        }, [options, searchTerm, canSearch]);

        const handleKeyDown = useCallback(
            (event: React.KeyboardEvent) => {
                if (event.key === 'ArrowDown') {
                    event.preventDefault();
                    if (!open) {
                        openDropdown();
                    }
                    setFocusedIndex((prevIndex) => {
                        const newIndex = prevIndex < filteredOptions.length - 1 ? prevIndex + 1 : 0;
                        optionRefs.current[newIndex]?.scrollIntoView({ block: 'nearest' });
                        return newIndex;
                    });
                } else if (event.key === 'ArrowUp') {
                    event.preventDefault();
                    if (!open) {
                        openDropdown();
                    }
                    setFocusedIndex((prevIndex) => {
                        const newIndex = prevIndex > 0 ? prevIndex - 1 : filteredOptions.length - 1;
                        optionRefs.current[newIndex]?.scrollIntoView({ block: 'nearest' });
                        return newIndex;
                    });
                } else if (event.key === 'Enter' && focusedIndex >= 0 && open) {
                    event.preventDefault();
                    const option = filteredOptions[focusedIndex];
                    if (option) {
                        handleSelect(option.value.toString(), option.label);
                    }
                }
            },
            [filteredOptions, focusedIndex, handleSelect, open, openDropdown]
        );

        useEffect(() => {
            const handleOutsideClick = (event: MouseEvent) => {
                if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                    closeDropdown();
                }
            };

            if (open) {
                document.addEventListener('mousedown', handleOutsideClick);
            } else {
                document.removeEventListener('mousedown', handleOutsideClick);
            }

            return () => {
                document.removeEventListener('mousedown', handleOutsideClick);
            };
        }, [open, closeDropdown]);

        useEffect(() => {
            if (defaultValue && !selectedValue) {
                const defaultOption = options.find((option) => option.value === defaultValue);
                if (defaultOption) {
                    setSelectedValue(defaultOption.value.toString());

                    // Extract text from ReactNode label and set it as the selected label
                    const defaultLabelText = extractTextFromReactNode(defaultOption.label);
                    setSelectedLabel(defaultLabelText);
                }
            }
        }, [defaultValue, selectedValue, options]);

        return (
            <div
                ref={dropdownRef}
                className={`dropdown-component ${disabled || readOnly ? 'dropdown-component--disabled' : ''} ${
                    !label ? 'dropdown-component--no-label' : ''
                } ${props.className}`}
                onKeyDown={handleKeyDown}
                role="combobox"
                aria-expanded={open}
                aria-controls={`${dropdownId}-options`}
                aria-haspopup="listbox"
                tabIndex={0}
            >
                <TextInputComponent
                    ref={ref}
                    id={dropdownId}
                    label={label}
                    value={canSearch && open ? searchTerm : selectedLabel || ''}
                    onFocus={openDropdown}
                    onClick={openDropdown}
                    onChange={canSearch ? handleInputChange : noop}
                    error={error}
                    success={success}
                    disabled={disabled}
                    contentLeft={contentLeft}
                    contentRight={
                        contentRight
                            ? contentRight
                            : {
                                  children: <Icon icon={open ? 'chevron-up' : 'chevron-down'} />,
                                  onClick: toggleDropdown
                              }
                    }
                    variant={variant}
                    {...props}
                />

                {open && options.length > 0 && (
                    <ul
                        id={`${dropdownId}-options`}
                        className="dropdown-component__options"
                        role="listbox"
                        aria-labelledby={dropdownId}
                    >
                        {filteredOptions.length > 0 ? (
                            filteredOptions.map((option, index) => (
                                <li
                                    key={index}
                                    ref={(el) => (optionRefs.current[index] = el)}
                                    role="option"
                                    tabIndex={0}
                                    aria-selected={selectedValue === option.value.toString()}
                                    onClick={() => handleSelect(option.value.toString(), option.label)}
                                    className={`dropdown-component__option ${focusedIndex === index ? 'focused' : ''}`}
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter' || event.key === ' ') {
                                            handleSelect(option.value.toString(), option.label);
                                        }
                                    }}
                                >
                                    {highlightMatch(option.label, searchTerm)}
                                </li>
                            ))
                        ) : (
                            <li className="dropdown-component__option dropdown-component__no-options">
                                {optionNotFoundText ? optionNotFoundText : 'No options found'}
                            </li>
                        )}
                    </ul>
                )}
            </div>
        );
    }
);

export default DropdownComponent;
