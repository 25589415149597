import classNames from 'classnames';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { ReactElement } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import Link from 'ui-kit/link/link';

import withBackgroundImage from 'hoc/withBackgroundImage';

import { Background, BodyDescription, EyeBrow, HeroPropsV2, LinkHero, SubTitle, Title } from './hero-v2.props';
import './hero-v2.style.scss';

const HeroV2 = ({
    colorPalette = 'white',
    size = 'large',
    reverse = false,
    mainContentCols,
    image,
    background,
    title,
    subTitle,
    body,
    eyeBrow,
    link
}: HeroPropsV2): ReactElement => {
    const maxCols = 12;
    const mainHeroContainer = classNames('hero-v2', colorPalette, size, image?.position);

    const renderEyebrow = (eyeBrow?: EyeBrow) => {
        if (!eyeBrow) return null;
        return <div className={`h5 hero-eyebrow-text ${eyeBrow.color}`}>{eyeBrow.text}</div>;
    };

    const renderTitle = (title?: Title) =>
        title ? <h1 className={`hero-title ${title.color}`}>{title.text}</h1> : null;

    const renderSubTitle = (subTitle?: SubTitle) =>
        subTitle ? <h2 className={`hero-subtitle ${subTitle.color}`}>{subTitle.text}</h2> : null;

    const renderDescription = (description?: BodyDescription) =>
        description ? <p className={`h3 hero-text ${description.color}`}>{description.text}</p> : null;

    const renderLink = (link?: LinkHero, background?: Background) => {
        if (!link) return null;
        const location = background?.image ? 'Multiple Image Hero' : 'Rounded Hero';
        return (
            <Link
                className={`hero-link ${link.color}`}
                to={link.href}
                label={link.label as string}
                variant="cta-button"
                dataGALocation={location}
            />
        );
    };

    return (
        <Container
            fluid
            className={mainHeroContainer}
            data-ga-location={background?.image ? 'Multiple Image Hero' : 'Rounded Hero'}
            data-ga-title={title}
        >
            <Row className={`${reverse && 'hero-reversed'}`}>
                <Col xs="12" md="12" lg={mainContentCols ? mainContentCols : '6'}>
                    <div className="hero-content">
                        {renderEyebrow(eyeBrow)}
                        {renderTitle(title)}
                        {renderSubTitle(subTitle)}
                        {renderDescription(body)}
                        {renderLink(link, background)}
                    </div>
                </Col>

                {image && (
                    <Col
                        xs="12"
                        md="12"
                        lg={mainContentCols ? maxCols - mainContentCols : '6'}
                        className={`hero-image ${image.position}`}
                    >
                        <div className={`hero-image-container`}>
                            <GatsbyImage image={getImage(image.source) as IGatsbyImageData} alt={image.alt} />
                        </div>
                    </Col>
                )}
            </Row>
        </Container>
    );
};

export const HeroWithBackgroundImage = withBackgroundImage(HeroV2, 'heroBackground');
export default HeroV2;
