import React, { useState } from 'react';

import { ToggleTabListProps, ToggleTabListViewProps } from './toggle-tab-list.props';
import './toggle-tab-list.style.scss';

const ToggleTabList: React.FC<ToggleTabListProps> & {
    View: React.FC<ToggleTabListViewProps>;
} = ({ tabs, onChange, children, defaultTab, ...props }) => {
    const [selectedTab, setSelectedTab] = useState(defaultTab || tabs[0]);
    const handleTabClick = (tab: string) => {
        setSelectedTab(tab);
        onChange?.(tab);
    };
    return (
        <div className="toggle-tab-list__container" {...props}>
            <div className="toggle-tab-list__tab-list">
                {tabs.map((tab) => (
                    <button
                        key={tab}
                        className={`toggle-tab-list__tab-list__tab ${
                            selectedTab === tab ? 'toggle-tab-list__tab-list__tab--active' : ''
                        }`}
                        onClick={() => handleTabClick(tab)}
                    >
                        {tab}
                    </button>
                ))}
            </div>
            {/* Tab Content */}
            <div className="tab-content">
                {children.map((child) => (child.props.id === selectedTab ? child : null))}
            </div>
        </div>
    );
};
ToggleTabList.View = ({ id, children }: ToggleTabListViewProps) => {
    return <>{children}</>; // Content rendering handled by ToggleTabList
};
export { ToggleTabList };
