import BirdiSelectInfoBannerMenHealth from 'assets/images/birdi-select-info-banner-men-health.png';
import BirdiSelectInfoBannerMentalCare from 'assets/images/birdi-select-info-banner-mental-care.png';
import BirdiSelectInfoBannerRxRefills from 'assets/images/birdi-select-info-banner-rx-refills.png';
import BirdiSelectInfoBannerSkinCare from 'assets/images/birdi-select-info-banner-skin-care.png';
import BirdiSelectInfoBannerThroat from 'assets/images/birdi-select-info-banner-throat.png';
import BirdiSelectInfoBannerWomenHealth from 'assets/images/birdi-select-info-banner-women-health.png';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { ReactNode, useEffect, useState } from 'react';

import BadgeStatus from 'ui-kit/bagde/badge-status';

import { Icon } from 'display-components/icon-list/icon-list';

import { BREAKPOINTS } from 'const/breakpoints';

import useWindowDimensions from 'hooks/useWindowDimensions';

import './birdi-select-info-banner-medication-results.style.scss';

interface BirdiSelectInfoBannerMedicationResultsProps {
    icon: Icon;
}

interface BannerProps {
    tag: ReactNode;
    title: string;
    image: string;
    backgroundColor?: string;
}

export const BirdiSelectInfoBannerMedicationResults = ({ icon }: BirdiSelectInfoBannerMedicationResultsProps) => {
    const { t } = useTranslation();

    if (!icon || typeof icon.id === 'undefined') {
        return <></>;
    }

    switch (icon.id) {
        case 1:
            return (
                <Banner
                    backgroundColor="#FFF5E5"
                    title={t('pages.birdiSelect.browseMedicationsResults.infoBanner.womenHealth')}
                    image={BirdiSelectInfoBannerWomenHealth}
                    tag={
                        <div>
                            <BadgeStatus icon={icon} variant="feature" label={icon.title} />
                        </div>
                    }
                />
            );
        case 2:
            return (
                <Banner
                    backgroundColor="#e5f4ff"
                    title={t('pages.birdiSelect.browseMedicationsResults.infoBanner.urgentCare')}
                    image={BirdiSelectInfoBannerThroat}
                    tag={
                        <div>
                            <BadgeStatus icon={icon} variant="feature" label={icon.title} />
                        </div>
                    }
                />
            );
        case 3:
            return (
                <Banner
                    backgroundColor="#ebfaf4"
                    title={t('pages.birdiSelect.browseMedicationsResults.infoBanner.mensHealth')}
                    image={BirdiSelectInfoBannerMenHealth}
                    tag={
                        <div>
                            <BadgeStatus icon={icon} variant="feature" label={icon.title} />
                        </div>
                    }
                />
            );
        case 4:
            return (
                <Banner
                    backgroundColor="#e5f4ff"
                    title={t('pages.birdiSelect.browseMedicationsResults.infoBanner.rxRefills')}
                    image={BirdiSelectInfoBannerRxRefills}
                    tag={
                        <div>
                            <BadgeStatus icon={icon} variant="feature" label={icon.title} />
                        </div>
                    }
                />
            );
        case 5:
            return (
                <Banner
                    backgroundColor="#fff5e5"
                    title={t('pages.birdiSelect.browseMedicationsResults.infoBanner.skinCare')}
                    image={BirdiSelectInfoBannerSkinCare}
                    tag={
                        <div>
                            <BadgeStatus icon={icon} variant="feature" label={icon.title} />
                        </div>
                    }
                />
            );
        case 6:
            return (
                <Banner
                    backgroundColor="#ebfaf4"
                    title={t('pages.birdiSelect.browseMedicationsResults.infoBanner.mentalHealth')}
                    image={BirdiSelectInfoBannerMentalCare}
                    tag={
                        <div>
                            <BadgeStatus icon={icon} variant="feature" label={icon.title} />
                        </div>
                    }
                />
            );
        default:
            return <></>;
    }
};

const Banner = ({ tag, title, image, backgroundColor }: BannerProps) => {
    const [useBackgroundColor, setUseBackgroundColor] = useState(backgroundColor);
    const { width } = useWindowDimensions();

    useEffect(() => {
        if (width <= BREAKPOINTS.md) {
            setUseBackgroundColor(backgroundColor);
        } else {
            setUseBackgroundColor('transparent');
        }
    }, [width, backgroundColor]);

    return (
        <div
            className="banner__tag"
            style={{
                backgroundImage: `url(${image})`,
                backgroundPosition: 'right 0rem',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '13rem',
                backgroundColor: useBackgroundColor
            }}
        >
            {tag}
            <h2>{title}</h2>
        </div>
    );
};
